import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  row: null
};

const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    setRow(state, action) {
      state.row = action.payload;
    },
    clearState(state) {
      state.row = null;
    }
  }
});

export const { setRow, clearState } = signatureSlice.actions;

export default signatureSlice.reducer;
