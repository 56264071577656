import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InactivityModal } from '../InactivityModal/InactivityModal';
import Timer from '../Timer/Timer';

const SessionTimeout = () => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [countdown, setCountdown] = useState(15 * 60 * 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [tokensRemoved, setTokensRemoved] = useState(false);
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const resetTimer = () => {
    setLastActivity(Date.now());
    setCountdown(15 * 60 * 1000);
    setShowTimer(false);
    setTokensRemoved(false);
  };

  useEffect(() => {
    const handleActivity = () => resetTimer();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    timerRef.current = setInterval(() => {
      const timeElapsed = Date.now() - lastActivity;
      const remainingTime = 15 * 60 * 1000 - timeElapsed;
      setCountdown(remainingTime);

      if (remainingTime <= 10 * 60 * 1000 && !showTimer) {
        setShowTimer(true);
      }

      if (remainingTime <= 0 && !tokensRemoved) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setTokensRemoved(true);
        navigate('/');
        setTimeout(() => {
          setIsModalOpen(true);
        }, 500);
      }
    }, 1000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      clearInterval(timerRef.current);
    };
  }, [lastActivity, navigate, showTimer, tokensRemoved]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {showTimer && <Timer countdown={countdown} />}
      <InactivityModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
};

export default SessionTimeout;
