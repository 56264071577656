import ACLGuard from '@comp/ACLGuard';
import Loadable from '@root/routes/Loadable';
import { lazy } from 'react';
// import ACLGuard from '@comp/ACLGuard';

const CompanyList = Loadable(lazy(() => import('@pages/company/index')));
const CompanyItem = Loadable(lazy(() => import('@pages/company/CompanyItem')));
const CreateCompany = Loadable(
  lazy(() => import('@pages/company/CreateCompany'))
);
const Signatures = Loadable(
  lazy(() => import('@pages/company/signatures/Signatures'))
);
const SignaturesCreate = Loadable(
  lazy(() => import('@pages/company/signatures/SignaturesCreate'))
);
// const MerchantModelId = Loadable(lazy(() => import('@pages/merchant/id')));
const CompanyUpdate = Loadable(
  lazy(() => import('@pages/company/CompanyUpdate'))
);

// const MerchantModelCreate = Loadable(
//   lazy(() => import('@pages/merchant/create'))
// );

// const MerchantTokenUpdate = Loadable(
//   lazy(() => import('@pages/merchant/_token/update'))
// );

// const MerchantDepositUpdate = Loadable(
//   lazy(() => import('@pages/merchant/_deposit/update'))
// );

export const companyRoute = {
  path: 'companies',
  children: [
    {
      index: true,
      element: (
        <ACLGuard can="read">
          <CompanyList />
        </ACLGuard>
      )
    },
    {
      path: ':id',
      element: (
        <ACLGuard can="read">
          <CompanyItem />
        </ACLGuard>
      )
    },
    {
      path: 'create',
      element: (
        <ACLGuard can="create">
          <CreateCompany />
        </ACLGuard>
      )
    },
    {
      path: 'staff/:id',
      element: (
        <ACLGuard can="details">
          <Signatures />
        </ACLGuard>
      )
    },
    {
      path: 'staff/create/:id',
      element: (
        <ACLGuard can="details">
          <SignaturesCreate />
        </ACLGuard>
      )
    },
    // {
    //   path: 'id/:id',
    //   element: (
    //     <ACLGuard can="details">
    //       <MerchantModelId />
    //     </ACLGuard>
    //   )
    // },
    {
      path: 'update/:id',
      element: (
        <ACLGuard can="update">
          <CompanyUpdate />
        </ACLGuard>
      )
    }

    // {
    //   path: 'token/:id',
    //   element: (
    //     <ACLGuard can="getMerchantKey">
    //       <MerchantTokenUpdate />
    //     </ACLGuard>
    //   )
    // },
    // {
    //   path: 'deposit/:id',
    //   element: (
    //     <ACLGuard can="depositLimitEdit">
    //       <MerchantDepositUpdate />
    //     </ACLGuard>
    //   )
    // }
  ]
};
