import { experimentalStyled } from '@mui/material/styles';

const LogoRoot = experimentalStyled('svg')``;
function Logo(props) {
  return (
    <LogoRoot
      height="52"
      version="1.1"
      viewBox="0 0 194 45"
      width="194"
      {...props}
    >
      <path
        d="M83.4472 18.5771C82.9248 17.9859 82.2065 17.5261 81.2923 17.1976C80.3781 16.8692 79.268 16.6721 78.0926 16.6721H71.1055V34.6049H73.7828V28.2332H78.0926C79.3333 28.2332 80.3781 28.0361 81.2923 27.7077C82.2065 27.3793 82.9248 26.9194 83.4472 26.3282C83.9696 25.7371 84.3614 25.1459 84.5573 24.489C84.8185 23.8321 84.9491 23.1752 84.9491 22.4527C84.9491 21.7301 84.8185 21.0075 84.5573 20.4163C84.3614 19.7595 83.9696 19.1026 83.4472 18.5771ZM73.7828 19.2339H77.8967C79.5292 19.2339 80.7046 19.5624 81.2923 20.1536C81.9453 20.7448 82.2718 21.533 82.2718 22.4527C82.2718 23.3723 81.9453 24.0949 81.2923 24.7517C80.6393 25.3429 79.5292 25.6714 77.8967 25.6714H73.7828V19.2339Z"
        fill="white"
      />
      <path
        d="M95.9847 22.7813C94.8093 21.6646 93.438 21.0734 91.8708 21.0734C90.2383 21.0734 88.7364 21.7302 87.4957 22.9126C86.2551 24.1607 85.6021 25.8029 85.6021 27.9049C85.6021 30.0069 86.2551 31.6491 87.4957 32.8972C88.7364 34.1453 90.173 34.7365 91.8708 34.7365C93.438 34.7365 94.8093 34.1453 95.9847 33.0286L96.2459 34.4737H98.662V21.4018H96.2459L95.9847 22.7813ZM95.9194 25.4088V30.4667C94.8746 31.6491 93.6339 32.2403 92.1973 32.2403C91.0219 32.2403 90.1077 31.8462 89.3894 31.1236C88.6711 30.4011 88.3446 29.2844 88.3446 27.9706C88.3446 26.5912 88.6711 25.5401 89.3894 24.8176C90.1077 24.095 91.0219 23.7009 92.1973 23.7009C93.6339 23.7009 94.8746 24.2921 95.9194 25.4088Z"
        fill="white"
      />
      <path
        d="M107.02 31.2549L103.102 21.4017H100.229L105.518 34.5393L103.298 40.3199H106.041L113.877 21.4017H111.004L107.02 31.2549Z"
        fill="white"
      />
      <path
        d="M132.422 16.6721H117.73V19.2339H123.737V34.6049H126.415V19.2339H132.422V16.6721Z"
        fill="white"
      />
      <path
        d="M137.907 21.139C135.883 21.139 134.381 21.7959 133.336 23.1754C132.292 24.4891 131.769 26.197 131.769 28.2333C131.769 30.2697 132.357 31.9119 133.532 33.0942C134.708 34.2766 136.275 34.8678 138.234 34.8678C140.128 34.8678 141.825 34.4737 143.262 33.6854L143.393 33.6197V30.8609L143.066 31.0579C141.891 31.8462 140.389 32.306 138.952 32.306C137.516 32.306 136.471 31.9776 135.752 31.3864C135.099 30.7952 134.708 30.0069 134.577 29.0216H144.176V27.9706C144.176 26.0656 143.654 24.4234 142.609 23.1097C141.434 21.7959 139.866 21.139 137.907 21.139ZM134.446 26.7225C134.577 25.6715 134.904 24.8833 135.557 24.3577C136.275 23.7666 136.993 23.5038 137.907 23.5038C138.756 23.5038 139.54 23.7666 140.258 24.3577C140.911 24.8833 141.303 25.6715 141.368 26.7225H134.446Z"
        fill="white"
      />
      <path
        d="M152.926 21.139C151.033 21.139 149.4 21.7959 148.094 22.9783C146.788 24.2264 146.07 25.8686 146.07 27.9706C146.07 30.0726 146.723 31.7148 148.094 32.9629C149.4 34.2109 151.033 34.8021 152.926 34.8021C154.428 34.8021 155.93 34.408 157.367 33.5541L157.497 33.4884V30.7952L157.171 30.9922C155.995 31.7805 154.624 32.1746 153.188 32.1746C151.816 32.1746 150.706 31.7805 149.988 30.9922C149.204 30.204 148.812 29.153 148.812 27.9049C148.812 26.5911 149.204 25.5401 149.988 24.8176C150.771 24.0293 151.816 23.6352 153.188 23.6352C154.624 23.6352 155.995 24.0293 157.171 24.8176L157.497 25.0146V22.3214L157.367 22.2557C155.995 21.5332 154.494 21.139 152.926 21.139Z"
        fill="white"
      />
      <path
        d="M170.753 22.5841C169.904 21.5988 168.794 21.1389 167.358 21.1389C165.725 21.1389 164.223 21.7958 162.917 23.1096V15.8839H160.24V34.605H162.917V25.9342C164.158 24.4233 165.399 23.7008 166.77 23.7008C168.468 23.7008 169.317 24.6204 169.317 26.5254V34.605H171.994V26.2626C172.059 24.8175 171.602 23.5694 170.753 22.5841Z"
        fill="white"
      />
      <path
        d="M25.4609 15.4242C27.3723 15.4242 28.9218 13.8655 28.9218 11.9428C28.9218 10.02 27.3723 8.4613 25.4609 8.4613C23.5495 8.4613 22 10.02 22 11.9428C22 13.8655 23.5495 15.4242 25.4609 15.4242Z"
        fill="white"
      />
      <path
        d="M25.4609 29.4815C27.3723 29.4815 28.9218 27.9228 28.9218 26C28.9218 24.0773 27.3723 22.5186 25.4609 22.5186C23.5495 22.5186 22 24.0773 22 26C22 27.9228 23.5495 29.4815 25.4609 29.4815Z"
        fill="white"
      />
      <path
        d="M25.4609 43.5387C27.3723 43.5387 28.9218 41.98 28.9218 40.0573C28.9218 38.1345 27.3723 36.5758 25.4609 36.5758C23.5495 36.5758 22 38.1345 22 40.0573C22 41.98 23.5495 43.5387 25.4609 43.5387Z"
        fill="white"
      />
      <path
        d="M39.3698 15.4242C41.2812 15.4242 42.8307 13.8655 42.8307 11.9428C42.8307 10.02 41.2812 8.4613 39.3698 8.4613C37.4584 8.4613 35.9089 10.02 35.9089 11.9428C35.9089 13.8655 37.4584 15.4242 39.3698 15.4242Z"
        fill="white"
      />
      <path
        d="M39.3698 29.4815C41.2812 29.4815 42.8307 27.9228 42.8307 26C42.8307 24.0773 41.2812 22.5186 39.3698 22.5186C37.4584 22.5186 35.9089 24.0773 35.9089 26C35.9089 27.9228 37.4584 29.4815 39.3698 29.4815Z"
        fill="white"
      />
      <path
        d="M39.3698 43.5387C41.2812 43.5387 42.8307 41.98 42.8307 40.0573C42.8307 38.1345 41.2812 36.5758 39.3698 36.5758C37.4584 36.5758 35.9089 38.1345 35.9089 40.0573C35.9089 41.98 37.4584 43.5387 39.3698 43.5387Z"
        fill="white"
      />
      <path
        d="M53.2133 15.4242C55.1247 15.4242 56.6742 13.8655 56.6742 11.9428C56.6742 10.02 55.1247 8.4613 53.2133 8.4613C51.3019 8.4613 49.7524 10.02 49.7524 11.9428C49.7524 13.8655 51.3019 15.4242 53.2133 15.4242Z"
        fill="white"
      />
      <path
        d="M53.2133 29.4815C55.1247 29.4815 56.6742 27.9228 56.6742 26C56.6742 24.0773 55.1247 22.5186 53.2133 22.5186C51.3019 22.5186 49.7524 24.0773 49.7524 26C49.7524 27.9228 51.3019 29.4815 53.2133 29.4815Z"
        fill="white"
      />
      <path
        d="M53.2133 43.5387C55.1247 43.5387 56.6742 41.98 56.6742 40.0573C56.6742 38.1345 55.1247 36.5758 53.2133 36.5758C51.3019 36.5758 49.7524 38.1345 49.7524 40.0573C49.7524 41.98 51.3019 43.5387 53.2133 43.5387Z"
        fill="white"
      />
    </LogoRoot>
  );
}

export default Logo;
