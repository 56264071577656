import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { showConfirm } from '@slices/dialog';

function AlertDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      showConfirm({
        title: '',
        isOpen: false,
        okCallback: undefined,
        text: ''
      })
    );
  };

  const dialog = useSelector((state) => state.dialog);

  return (
    <div>
      <Dialog
        open={dialog.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        {dialog.text !== undefined && dialog.text !== '' ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialog.text}
            </DialogContentText>
          </DialogContent>
        ) : null}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Cancel button')}
          </Button>
          {dialog.okCallback !== undefined ? (
            <Button
              onClick={() => {
                dialog.okCallback();
                handleClose();
              }}
              color="secondary"
            >
              {t('Ok button')}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
