import axios from '@lib/axios';
import { ThemeProvider } from '@mui/material';
import { app } from '@root/config';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SecondFactorModal from '~/components/authentication/login/TwoFactorModal';
import useSettings from '~/hooks/useSettings';
import { createCustomTheme } from '~/theme';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export function AuthProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [secondFactor, setSecondFactor] = useState(false);
  const [emailFor2FA, setEmailFor2FA] = useState('');
  const [passwordFor2FA, setPasswordFor2FA] = useState('');
  const [code, setCode] = useState('');
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const handleClose = () => setSecondFactor(false);

  const initialize = async () => {
    if (window.location.pathname === '' || window.location.pathname === '/') {
      navigate('/board');
    }
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        await axios.get(`${app.api}/user/me`).then((response) => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                ...response.data,
                permissions: {
                  ...response.data.permissions,
                  superAdmin: response.data.permissions.superAdmin || []
                },
                id: response.data.hash,
                avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
                name: `${response.data.firstName} ${response.data.lastName}`,
                plan: 'Premium'
              }
            }
          });
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
      navigate('/board');
    }
  };

  useEffect(() => {
    initialize();
  }, [navigate]);

  const login = async (email, password) => {
    await axios
      .post(`${app.api}/login`, { email, password })
      .then((response) => {
        if (response.data.needSecondFactor) {
          setEmailFor2FA(email);
          setPasswordFor2FA(password);
          setSecondFactor(true);
        } else {
          localStorage.setItem('accessToken', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('accessId', response.data.user.hash);
          localStorage.setItem('merchId', response.data.user.merchantId);
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleSecondFactorSubmit = async () => {
    try {
      const response = await axios.post(`${app.api}/login/second-factor`, {
        email: emailFor2FA,
        password: passwordFor2FA,
        code
      });

      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('accessId', response.data.user.hash);
      localStorage.setItem('merchId', response.data.user.merchantId);
      handleClose();
      window.location.reload();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const logout = async () => {
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
    setTimeout(() => navigate('/board'), 200);
  };

  const register = async (phone, password, linkToken = null) => {
    await axios
      .post(`${app.api}/registration`, {
        phone,
        password,
        inviteHash: linkToken
      })
      .then(() => {
        toast.success(t('Success registration'));
        navigate('/board');
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const passwordRecovery = async (email, cb) => {
    await axios
      .post(`${app.api}/password/forget`, {
        email
      })
      .then(() => {
        cb(true);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          toast.error(t(err.response.data.message));
        } else {
          toast.error('Ошибка отправки данных. Повторите позже');
        }
        cb(false);
      });
  };

  const passwordReset = async (email, password, token) => {
    await axios
      .post(`${app.api}/password/change/${token}`, {
        email,
        password
      })
      .then(() => {
        toast.success(t('Password was successfully changed'));
        navigate('/board');
      })
      .catch((err) => {
        toast.error(t(err.response.data.message));
      });
  };

  const getAccess = (path, name) =>
    state.user.permissions.superAdmin.indexOf('all') !== -1 ||
    (state.user.permissions[path] !== undefined &&
      state.user.permissions[path].includes(name));

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider
        value={{
          ...state,
          platform: 'JWT',
          login,
          logout,
          register,
          passwordRecovery,
          passwordReset,
          getAccess,
          updateUserAsync: initialize
        }}
      >
        {children}
        <SecondFactorModal
          open={secondFactor}
          handleClose={handleClose}
          handleSubmit={handleSecondFactorSubmit}
          code={code}
          setCode={setCode}
        />
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
