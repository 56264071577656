import ToggleButtonGroup, {
  toggleButtonGroupClasses
} from '@mui/material/ToggleButtonGroup';
import MoonIcon from '@icons/Moon';
import SunIcon from '@icons/Sun';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import useSettings from '~/hooks/useSettings';
import { THEMES } from '@root/constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => {
  return {
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      height: '30px',
      margin: theme.spacing(0.5),
      border: 0,
      borderRadius: '50%',
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0
      },
      [`&:not(:last-of-type), &:not(:first-of-type)`]: {
        borderRadius: '50%'
      }
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: -1,
        borderLeft: '1px solid transparent'
      },
    [`&.toggle-theme .MuiToggleButton-root.Mui-selected`]: {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.light
    },
    [`&.toggle-theme .MuiToggleButton-root`]: {
      padding: '5px',
      color: theme.palette.primary.light
    }
  };
});

const ToggleThemeComponent = () => {
  const { settings, saveSettings } = useSettings();
  const [themeMode, setThemeMode] = useState(settings.theme);

  useEffect(() => {
    if (themeMode !== settings.theme) {
      saveSettings((prev) => ({ ...prev, theme: themeMode }));
    }
  }, [themeMode]);

  const handleTheme = (event, themeValue) => {
    setThemeMode(themeValue);
  };

  return (
    <Box sx={{ ml: 1 }}>
      <StyledToggleButtonGroup
        className="toggle-theme"
        onChange={handleTheme}
        exclusive
        value={themeMode}
        sx={{
          marginX: 1,
          backgroundColor: '#ffffff'
        }}
      >
        <ToggleButton color="primary" value={THEMES.LIGHT}>
          <SunIcon />
        </ToggleButton>
        <ToggleButton value={THEMES.DARK}>
          <MoonIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Box>
  );
};

export default ToggleThemeComponent;
