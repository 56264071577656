import { Suspense } from 'react';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) =>
  // eslint-disable-next-line func-names, react/display-name
  function (props) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
