import { createSlice } from '@reduxjs/toolkit';

const commissionsSlice = createSlice({
  name: 'commissions',
  initialState: {
    commissionData: null,
    typeCommissions: null
  },
  reducers: {
    setPercentage(state, action) {
      state.commissionData = action.payload;
    },
    clearPercentage(state) {
      state.commissionData = null;
    },

    setTypeCommissions(state, action) {
      state.typeCommissions = action.payload;
    },
    clearTypeCommissions(state) {
      state.typeCommissions = null;
    }
  }
});

export const {
  setPercentage,
  clearPercentage,
  setTypeCommissions,
  clearTypeCommissions
} = commissionsSlice.actions;

export default commissionsSlice.reducer;
