import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

const SecondFactorDialog = ({
  open,
  handleClose,
  handleSubmit,
  code,
  setCode
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Двофакторна автентифікація</DialogTitle>
      <DialogContent>
        <TextField
          label="Введіть код"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Закрити</Button>
        <Button onClick={handleSubmit} variant="contained">
          Підтвердити
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SecondFactorDialog;
