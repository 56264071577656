import './styles/BaseSideBar.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, MenuItem, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuth from '@hooks/useAuth';
import axios from '@lib/axios';
import { app } from '@root/config';
import Scrollbar from './Scrollbar';
import NavSection from './NavSection';
import { useSideBarSections } from './use/useSideBarSections';
import BalanceBlock from './BalanceBlock';

function BaseSidebar(props) {
  const { user } = useAuth();
  const { sections } = useSideBarSections();

  const [merchId, setMerchId] = useState(() =>
    localStorage.getItem('merchId') !== null
      ? localStorage.getItem('merchId')
      : user.merchantId
  );

  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleChangeMerch = async (e) => {
    localStorage.setItem('merchId', e.target.value);
    setMerchId(e.target.value);
    await axios
      .post(`${app.api}/user/${user.hash}`, {
        merchantId: e.target.value
      })
      .then((response) => {
        localStorage.setItem('accessToken', response.data.token);
        window.location.replace('/board');
      });
  };

  useEffect(() => {
    const getData = async () => {
      if (openMobile && onMobileClose) {
        await onMobileClose();
      }
    };
    getData();
  }, [location.pathname]);

  const content = (
    <Box
      className="base-sidebar__container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box>
            <TextField
              fullWidth
              label="merchId"
              onChange={handleChangeMerch}
              select
              size="small"
              value={merchId}
              variant="outlined"
            >
              {user.merchants.map((item) => (
                <MenuItem value={item.merchantId} key={item.merchantId}>
                  {item.merchantName}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <BalanceBlock />
        <Divider sx={{ paddingY: 1 }} />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        className="base-sidebar"
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          className: 'base-sidebar__paper',
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          top: smUp ? '60px' : '45px',
          backgroundColor: 'background.paper'
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

BaseSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default BaseSidebar;
