import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/react-query/queryKeys';
import axios from '@lib/axios';
import { app } from '@root/config';

const getBalance = () =>
  axios
    .get(`${app.api}/board/depositBalance`)
    .then((response) => response.data);

const initBalance = {
  balance: 0,
  own_funds: 0,
  overdraft: 0,
  accepted_payments: 0,
  payments: 0,
  using_own_funds: 0,
  using_overdraft: 0,
  using_accepted_payments: 0
};

export const useDepositeBalance = (merchantId) => {
  const { data: balance = initBalance } = useQuery(
    [queryKeys.depositeBalance, { merchantId }],
    getBalance,
    {
      refetchInterval: 60000
    }
  );

  return { balance };
};
