import { Component } from 'react';
import { Typography } from '@mui/material';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography variant="h4" component="h4" sx={{ m: 3 }} gutterBottom>
          Ошибка работы приложения. Повторите позже
        </Typography>
      );
    }

    return this.props.children;
  }
}
