import { lazy } from 'react';
import ACLGuard from '@comp/ACLGuard';

const FinMonIndexPage = lazy(() => import('@pages/FinMon'));
const FinMonUpdate = lazy(() => import('@pages/FinMon/FinMonUpdate'));
const FinMonTerroristList = lazy(() =>
  import('@pages/FinMon/FinMonTerroristList')
);

const FinMonSanctionList = lazy(() =>
  import('@pages/FinMon/FinMonSanctionList')
);

export const finMonRoute = {
  path: 'fin-mon',
  children: [
    {
      index: true,
      element: (
        <ACLGuard can="read">
          <FinMonIndexPage />
        </ACLGuard>
      )
    },
    {
      path: ':id/update',
      element: (
        <ACLGuard can="update">
          <FinMonUpdate />
        </ACLGuard>
      )
    },
    {
      path: 'terrorists',
      element: (
        <ACLGuard can="read">
          <FinMonTerroristList />
        </ACLGuard>
      )
    },
    {
      path: 'sanction',
      element: (
        <ACLGuard can="read">
          <FinMonSanctionList />
        </ACLGuard>
      )
    }
  ]
};
