import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '@hooks/useAuth';
import ForbiddenRequest from '@pages/ForbiddenRequest';

function ACLGuard(props) {
  const { children, can } = props;
  const auth = useAuth();
  const location = useLocation();
  const path = location.pathname.split('/').filter((item) => item !== '')[0];

  if (
    auth.user.permissions[path] === undefined &&
    auth.user.permissions.superAdmin.indexOf('all') === -1
  ) {
    return <ForbiddenRequest permissionName={path} permissionRole={can} />;
  }

  if (
    auth.user.permissions[path] &&
    auth.user.permissions[path].indexOf(can) === -1 &&
    auth.user.permissions.superAdmin.indexOf('all') === -1
  ) {
    return <ForbiddenRequest permissionName={path} permissionRole={can} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

ACLGuard.propTypes = {
  children: PropTypes.node
};

export default ACLGuard;
