// eslint-disable-next-line import/no-extraneous-dependencies
import { app } from '@root/config';
import axios from 'axios';

const token = localStorage.getItem('accessToken');
if (token !== null && token !== undefined) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const handleRefreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) throw new Error('No refresh token available');

  try {
    const response = await axios.post(`${app.api}/refresh`, { refreshToken });
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${response.data.token}`;
    processQueue(null, response.data.token);
    return response.data.token;
  } catch (err) {
    processQueue(err, null);
    throw err;
  } finally {
    isRefreshing = false;
  }
};

axios.interceptors.response.use(
  (response) => {
    if (response.data.respCode === 2401) {
      throw new Error('Token expired');
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.data.respCode === 2401) {
      if (!isRefreshing) {
        isRefreshing = true;
        return handleRefreshToken()
          .then((newToken) => {
            response.config.headers['Authorization'] = `Bearer ${newToken}`;
            return axios(response.config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            response.config.headers['Authorization'] = `Bearer ${token}`;
            return axios(response.config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    }

    return Promise.reject(error);
  }
);

export const setupInterceptors = (setLoading) => {
  axios.interceptors.request.use(
    function (config) {
      if (config.method === 'post' || config.method === 'put') {
        setLoading(true);
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      if (
        response.config.method === 'post' ||
        response.config.method === 'put'
      ) {
        setLoading(false);
      }
      return response;
    },
    function (error) {
      setLoading(false);
      return Promise.reject(error);
    }
  );
};

export default axios;
