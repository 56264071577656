import Loadable from '@root/routes/Loadable';
import { lazy } from 'react';

const RegisterPayments = Loadable(
  lazy(() => import('~/pages/registerPayments/registerPayments/index'))
);
const CheckRegisterPayments = Loadable(
  lazy(() => import('~/pages/registerPayments/checkPayments/index'))
);

export const registerPaymentsRoute = {
  path: 'register',
  children: [
    {
      index: true,
      path: 'pay',
      element: <RegisterPayments />
    },
    {
      path: 'check',
      element: <CheckRegisterPayments />
    }
  ]
};
