import useAuth from '@hooks/useAuth';
import {
  AccountBalance,
  BlurLinear,
  CenterFocusWeak,
  Code,
  DescriptionOutlined,
  Dns,
  Group,
  Home,
  LinearScale,
  LocalConvenienceStore,
  Lock,
  PriceCheck,
  Receipt,
  Security,
  Storefront,
  Timeline
} from '@material-ui/icons';
import WalletIcon from '@mui/icons-material/Wallet';
import { useTranslation } from 'react-i18next';
export const useSideBarSections = () => {
  const { user, getAccess } = useAuth();
  const { t } = useTranslation();

  const getActiveStatus = (name) =>
    user.permissions[name] !== undefined ||
    user.permissions.superAdmin.indexOf('all') !== -1;

  const sections = [
    {
      title: '',
      items: [
        {
          title: t('Dashboard menu'),
          path: '/board',
          icon: <Home fontSize="small" />,
          active: true
        },
        {
          title: t('Balanc'),
          path: '/balance',
          icon: <WalletIcon fontSize="small" />,
          active: getAccess('general', 'canSeeAggregatetBalance')
        },
        {
          title: t('City24'),
          path: '/city24',
          icon: <LocalConvenienceStore fontSize="small" />,
          active: getActiveStatus('users'),
          children: [
            {
              title: t('Transaction menu'),
              path: '/city24/transactions',
              icon: <Receipt fontSize="small" />,
              active: getActiveStatus('users')
            },
            {
              title: t('Merchant menu'),
              path: '/city24/merchants',
              icon: <Storefront fontSize="small" />,
              active: getActiveStatus('users')
            },
            {
              title: t('Terminals menu'),
              path: '/city24/terminals',
              icon: <BlurLinear fontSize="small" />,
              active: getActiveStatus('users')
            },
            {
              title: t('Keys menu'),
              path: '/city24/keys',
              icon: <Lock fontSize="small" />,
              active: getActiveStatus('users')
            }
          ]
        },
        {
          title: t('Transaction menu'),
          path: '/transactions',
          icon: <Receipt fontSize="small" />,
          active: getActiveStatus('transactions')
        },
        {
          title: t('FinMon'),
          path: '/fin-mon',
          icon: <Receipt fontSize="small" />,
          active: getActiveStatus('fin-mon'),
          children: [
            {
              title: t('FinMon'),
              path: '/fin-mon',
              icon: <Receipt fontSize="small" />,
              active: getActiveStatus('fin-mon')
            },
            {
              title: t('terrorists_list'),
              path: '/fin-mon/terrorists',
              icon: <Receipt fontSize="small" />,
              active: getActiveStatus('fin-mon')
            },
            {
              title: t('sanctions_list'),
              path: '/fin-mon/sanction',
              icon: <Receipt fontSize="small" />,
              active: getActiveStatus('fin-mon') || true
            }
          ]
        },
        {
          title: t('Users menu'),
          path: '/users',
          icon: <Group fontSize="small" />,
          active: getActiveStatus('users'),
          children: [
            {
              title: t('Users active menu'),
              path: '/users',
              icon: <Group fontSize="small" />,
              active: getActiveStatus('users')
            },
            {
              title: t('Users inactive menu'),
              path: '/users/inactive',
              icon: <Group fontSize="small" />,
              active: getActiveStatus('users')
            }
          ]
        },
        {
          title: t('Role menu'),
          path: '/roles',
          icon: <Security fontSize="small" />,
          active: getActiveStatus('roles')
        },
        {
          title: t('Banks menu'),
          path: '/banks',
          icon: <AccountBalance fontSize="small" />,
          active: getActiveStatus('banks')
        },
        {
          title: t('Flow menu'),
          path: '/flows',
          icon: <Timeline fontSize="small" />,
          active: getActiveStatus('flows')
        },
        {
          title: t('Gateway menu'),
          path: '/gateways',
          icon: <CenterFocusWeak fontSize="small" />,
          active: getActiveStatus('gateways')
        },
        {
          title: t('Cascading menu'),
          path: '/cascading',
          icon: <LinearScale fontSize="small" />,
          active: getActiveStatus('cascading')
        },
        {
          title: t('Terminals menu'),
          path: '/terminals',
          icon: <BlurLinear fontSize="small" />,
          active: getActiveStatus('terminals')
        },
        {
          title: t('Merchant menu'),
          path: '/merchants',
          icon: <Storefront fontSize="small" />,
          active: getActiveStatus('merchants')
        },
        {
          title: t('Companies'),
          path: '/companies',
          icon: <Storefront fontSize="small" />,
          active: getActiveStatus('companies')
        },
        {
          title: t('Reconciliation menu'),
          path: '/reconciliation',
          icon: <PriceCheck fontSize="small" />,
          active: getActiveStatus('reconciliation')
        },
        {
          title: t('Reports'),
          path: '/export',
          icon: <DescriptionOutlined fontSize="small" />,
          active: getActiveStatus('export'),
          children: [
            {
              title: t('generated_reports_list'),
              path: '/export',
              icon: <DescriptionOutlined fontSize="small" />,
              active: getActiveStatus('export')
            }
            // {
            //   title: t('Description menu city24'),
            //   path: '/export/city24',
            //   icon: <DescriptionOutlined fontSize="small" />,
            //   active: getActiveStatus('export')
            // }
          ]
        },
        {
          title: t('Bin menu'),
          path: '/bin',
          icon: <Dns fontSize="small" />,
          active: getActiveStatus('bin')
        },
        {
          title: t('Codes menu'),
          path: '/codes',
          icon: <Code fontSize="small" />,
          active: getActiveStatus('codes')
        },
        {
          title: t('Register payments'),
          path: '/register',
          icon: <Group fontSize="small" />,
          active:
            getAccess('merchants', 'canMakeRegisterPayments') ||
            getAccess('merchants', 'canMakeRegisterCheck'),
          children: [
            {
              title: t('Check registry'),
              path: '/register/check',
              icon: <Receipt fontSize="small" />,
              active: getAccess('merchants', 'canMakeRegisterCheck')
            },
            {
              title: t('Register payments'),
              path: '/register/pay',
              icon: <Receipt fontSize="small" />,
              active: getAccess('merchants', 'canMakeRegisterPayments')
            }
          ]
        }
      ]
    }
  ];

  return { sections };
};
