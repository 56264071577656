import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InactivityModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <p>{t('Active user')}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ок</Button>
      </DialogActions>
    </Dialog>
  );
};
